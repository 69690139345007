import '@css/app.pcss'
import LazyLoad from "vanilla-lazyload";
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faAngleRight, faArrowRight, faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faFacebookSquare, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

document.querySelector('.icon-scroll')?.addEventListener('click', function () {
    let elemRect = document.querySelector('.icon-scroll')?.getBoundingClientRect();

    if (elemRect && elemRect.top > window.innerHeight * 0.5) {
        window.scrollTo(0, elemRect.top + 50);
    } else {
        window.scrollTo(0, 0);
    }
});

new LazyLoad({
    elements_selector: '.lazy',
    threshold: 0,
    callback_loaded: function (element) {
        element.classList.add('loaded');
    }
});

// We are only using the user-astronaut icon
library.add(
    faAngleRight,
    faArrowRight,
    faBars,
    faTimes,
    faFacebookSquare,
    faInstagram,
    faLinkedinIn
)

// Replace any existing <i> tags with <svg> and set up a MutationObserver to
// continue doing this as the DOM changes.
dom.watch()


